.navigator {
  width: 100%;
  background-color: transparent;
  overflow: hidden;

  .navigator-area {
    width: 100vw;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .navigator-area-with-pannel {
      width: calc(100vw - 18rem) !important;
      height: calc(100vh - 8rem);

      @media only screen and (max-width: 940px) {
        width: calc(100vw - 6rem) !important;
      }

      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
