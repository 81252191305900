@import '../../../../styles/colors.scss';

.user-header-menu {
  width: 12rem;
  height: 4rem;
  margin-right: 2rem;

  border-radius: 0.5rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  gap: 0.5rem;

  color: $LP-iconColor;
  font-size: 1rem;

  color: $LP-iconColor;

  text-align: right;
  text-overflow: ellipsis;

  user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -ms-user-select: none;

  .user-header-menu-avatar {
    width: 3rem !important;
    height: 3rem !important;

    background-color: $LP-iconColor;
    color: $LP-bgColor;

    img {
      width: 3rem !important;
      height: 3rem !important;

      background-color: $LP-iconColor;
      color: $LP-bgColor;
    }
  }

  @media only screen and (max-width: 940px) {
    width: 5rem;
    font-size: 0.8rem;
    text-align: center;
    flex-direction: column-reverse;
  }
}

.user-header-menu-drop-menu {
  width: 10rem !important;
  display: flex !important;

  margin-top: 0.5rem;

  .menu-button-template {
    .p-menuitem-link {
      color: $LP-bgColor;
      flex-direction: row-reverse;
      column-gap: 1rem;
    }
    width: 10rem;
    height: 3rem;
  }

  @media only screen and (max-width: 940px) {
    margin-top: 2rem;
  }
}
