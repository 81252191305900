@font-face {
  font-family: 'Poppins-Black';
  src: local('Poppins-Black'),
    url(../fonts/Poppins/Poppins-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: local('Poppins-BlackItalic'),
    url(../fonts/Poppins/Poppins-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: local('Poppins-BoldItalic'),
    url(../fonts/Poppins/Poppins-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: local('Poppins-ExtraBold'),
    url(../fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: local('Poppins-ExtraBoldItalic'),
    url(../fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: local('Poppins-ExtraLight'),
    url(../fonts/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: local('Poppins-ExtraLightItalic'),
    url(../fonts/Poppins/Poppins-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: local('Poppins-Italic'),
    url(../fonts/Poppins/Poppins-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url(../fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: local('Poppins-LightItalic'),
    url(../fonts/Poppins/Poppins-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: local('Poppins-MediumItalic'),
    url(../fonts/Poppins/Poppins-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: local('Poppins-SemiBoldItalic'),
    url(../fonts/Poppins/Poppins-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'),
    url(../fonts/Poppins/Poppins-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: local('Poppins-ThinItalic'),
    url(../fonts/Poppins/Poppins-ThinItalic.ttf) format('truetype');
}
