@import './colors.scss';

.p-button {
  color: $LP-txtColor-alternative !important;
  background-color: $LP-bgColor !important;
  border: 1px solid $LP-bgColor !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: $LP-bgColor, $alpha: 0.5) !important;
  }
  &.p-button-outlined {
    background-color: transparent !important;
    color: $LP-bgColor !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-bgColor !important;
    border-color: transparent !important;
  }
}

.p-button.p-button-secondary {
  color: $LP-txtColor !important;
  background-color: $LP-mgColor !important;
  border: 1px solid $LP-mgColor !important;
  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($color: $LP-mgColor, $alpha: 0.5) !important;
  }
  &.p-button-outlined {
    background-color: transparent !important;
    color: $LP-mgColor !important;
  }
  &.p-button-text {
    background-color: transparent !important;
    color: $LP-mgColor !important;
    border-color: transparent !important;
  }
}

// .p-button.p-button-success {
//   color: $PDCE-txtColor-alternative !important;
//   background-color: green !important;
//   border: 1px solid green !important;
//   &:focus {
//     box-shadow: 0 0 0 0.15rem rgba($color: green, $alpha: 0.5) !important;
//   }
//   &.p-button-outlined {
//     background-color: transparent !important;
//     color: green !important;
//   }
//   &.p-button-text {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//     border-color: transparent !important;
//   }
// }
// .p-button.p-button-info {
//   color: $PDCE-txtColor-alternative !important;
//   background-color: blue !important;
//   border-color: blue !important;
//   border: 1px solid blue !important;
//   &:focus {
//     box-shadow: 0 0 0 0.15rem rgba($color: blue, $alpha: 0.5) !important;
//   }
//   &.p-button-outlined {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//   }
//   &.p-button-text {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//     border-color: transparent !important;
//   }
// }
// .p-button.p-button-warning {
//   color: $PDCE-txtColor-alternative !important;
//   background-color: orange !important;
//   border-color: orange !important;
//   border: 1px solid orange !important;
//   &:focus {
//     box-shadow: 0 0 0 0.15rem rgba($color: orange, $alpha: 0.5) !important;
//   }
//   &.p-button-outlined {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//   }
//   &.p-button-text {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//     border-color: transparent !important;
//   }
// }
// .p-button.p-button-help {
//   color: $PDCE-txtColor-alternative !important;
//   background-color: purple !important;
//   border-color: purple !important;
//   border: 1px solid purple !important;
//   &:focus {
//     box-shadow: 0 0 0 0.15rem rgba($color: purple, $alpha: 0.5) !important;
//   }
//   &.p-button-outlined {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//   }
//   &.p-button-text {
//     background-color: transparent !important;
//     color: $PDCE-fgColor !important;
//     border-color: transparent !important;
//   }
// }
// .p-button.p-button-danger {
//   color: $PDCE-txtColor-alternative !important;
//   background-color: $main-errColor !important;
//   border-color: $main-errColor !important;
//   border: 1px solid $main-errColor !important;
//   &:focus {
//     box-shadow: 0 0 0 0.15rem rgba($color: $main-errColor, $alpha: 0.5) !important;
//   }
//   &.p-button-outlined {
//     background-color: transparent !important;
//     color: $main-errColor !important;
//   }
//   &.p-button-text {
//     background-color: transparent !important;
//     color: $main-errColor !important;
//     border-color: transparent !important;
//   }
// }
