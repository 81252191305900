@import '../../../styles/colors.scss';

#especialidades {
  &.container {
    height: 100%;
    padding: 1.5rem 2rem 2rem 2rem;
    background-color: #f9f9fb !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      max-width: 1250px;
      display: flex;
      justify-content: center;
      align-items: start;
      background-color: #f9f9fb !important;
      gap: 2rem;
      .title-content{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        .title {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: center;
          font-size: 3rem;
          font-weight: bold;
          padding-bottom: 1rem;
          color: $LP-bgColor;
          word-wrap: break-word;
          margin-bottom: 3rem;
          @media screen and (max-width: 770px) {
            padding: 0 1rem;
          }
        }
        @media screen and (max-width: 770px) {
          width: 100%;
        }
      }
      .cards-expertise {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
        align-items: start;
        @media screen and (max-width: 770px) {
          flex-direction: column;
        }
      }
      @media screen and (max-width: 770px) {
        flex-direction: column;
      }
    }
  }
}
