.contact-widget {
  z-index: 12;

  position: fixed;
  bottom: 1rem;
  right: 1rem;

  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    column-gap: 0.5rem;
    text-decoration: none !important;
  }

  .contact-widget-message {
    height: 2rem;
    padding: 0rem 1rem;

    background-color: white;

    border-radius: 2.5rem;
    box-shadow: 0px 0px 2px 1px rgba(4, 34, 59, 0.2);
    -moz-box-shadow: 0px 0px 2px 1px rgba(4, 34, 59, 0.2);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;

    color: #04223b;
    font-size: 14px;
    font-weight: 700;
    font-smooth: always;
    font-family: -apple-system, 'Roboto', sans-serif;

    @media only screen and (max-width: 940px) {
      display: none;
    }
  }

  .contact-widget-button {
    width: 3.5rem;
    height: 3.5rem;

    background-color: #25d366;

    border-radius: 100%;
    border: 5px solid white;
    box-shadow: 0px 0px 2px 1px rgba(4, 34, 59, 0.2);
    -moz-box-shadow: 0px 0px 2px 1px rgba(4, 34, 59, 0.2);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    svg {
      display: flex;
      color: white;
      font-size: 2.2rem;
    }
  }

  .contact-widget-message,
  .contact-widget-button,
  &:hover {
    cursor: pointer;
  }

  // Component Animation

  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;

  @keyframes bounce {
    from {
      transform: translateY(5px);
    }
    to {
      transform: translateY(-5px);
    }
  }

  @-webkit-keyframes bounce {
    from {
      transform: translateY(5px);
    }
    to {
      transform: translateY(-5px);
    }
  }
}
