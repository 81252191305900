@import '../../../../styles/colors.scss';

.header {
  position: absolute;

  width: 100%;
  height: 8rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  column-gap: 1rem;

  &.header-mode-relative {
    position: relative;
    background-color: $LP-bgColor;
  }

  .header-logo {
    padding-left: 2rem;
    height: 100%;
    width: 15rem;

    user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -ms-user-select: none;

    @media only screen and (max-width: 940px) {
      height: 4rem;
    }

    transition: 0.1s ease-out;
  }

  .header-responsive-menu {
    padding-right: 2rem;

    .header-responsive-menu-area {
      z-index: 11;

      width: 100vw;
      height: 100vh;

      position: fixed;

      bottom: 0;
      right: 0;

      background-color: $LP-bgColor;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      row-gap: 1rem;

      .header-responsive-menu-area-button {
        width: 100vw;
        height: 8rem;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        padding-right: 4rem;
      }

      img {
        padding-left: 0;
        width: 18rem;
        height: 100%;
      }

      .header-responsive-menu-area-line {
        margin-top: 1.5rem;
        background-color: white;
        width: 20rem;
        height: 0.06rem;
      }

      ul {
        height: auto;
        list-style: none;
        padding-left: 0;

        li {
          color: $LP-iconColor;

          padding: 1rem;

          font-size: 1.5rem;
          font-weight: 700;
          font-smooth: always;
          font-family: -apple-system, 'Roboto', sans-serif;
          text-align: center;

          transition: 1s;

          a {
            text-decoration: none;
            color: inherit;
          }
          &:hover {
            color: $LP-mgColor;
          }
        }
      }
    }

    .header-responsive-menu-icon {
      display: flex;
      color: $LP-iconColor;
      font-size: 2rem;
      &:hover {
        cursor: pointer;
        color: $LP-mgColor;
      }
    }
  }

  .header-menu-list > ul {
    list-style: none;
    padding-right: 2rem;

    li {
      color: $LP-iconColor;

      float: left;
      padding: 1rem;

      font-weight: 700;
      font-smooth: always;
      font-family: -apple-system, 'Roboto', sans-serif;

      transition: 1s;

      a {
        text-decoration: none;
        color: inherit;
      }
      &:hover {
        color: $LP-mgColor;
      }
    }
  }
}
