@import '../../../../styles/colors.scss';

.card-expertise {
  display: flex;
  flex-direction: column;
  width: 23rem;
  border-radius: 1rem;
  transition: 1s;
  background: linear-gradient(325.85deg, rgba(8, 104, 168, 0.131765) 0%, rgba(111, 204, 249, 0.2) 59.9%, rgba(111, 204, 249, 0.096) 100%);
  &:hover{
    transform: scale(0.9) translate(-5%, -5%);
    transition: 1s;
  }
  .card-expertise-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .card-expertise-header {
      width: 23rem;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1rem;
      padding: 2rem 2rem 0 2rem;
      i {
        width: 5rem;
        height: 5rem;
        background-color: $LP-bgColor;
        color: $LP-iconColor;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 1rem;
        margin-bottom: 1rem;
        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }

      .card-expertise-title {
        color: $LP-bgColor;
        font-weight: bold;
        text-align: start;
        width: 14rem;
        font-size: 1.5rem;
      }
    }
    .card-expertise-decriptions {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      padding: 0 2rem 2rem 2rem;
      border-radius: 0 0 1rem 1rem;
      .card-expertise-description {
        color: $LP-txtColor;
        font-size: 1rem;
        z-index: 2;
      }
    }
  }

  @media screen and (max-width: 770px) {
    // width: 100%;
    margin: 0 1rem;
  }
}
