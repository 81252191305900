@import '../../../styles/colors.scss';

#sobre-nos {
  &.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem 2rem 0rem 2rem;
    background-color: rgba(255, 255, 255, 0.9);

    .content {
      max-width: 1250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 2.5rem;
        font-weight: bold;
        padding-bottom: 1rem;
        width: 100%;
        padding: 0rem 3rem;
      }
      .descriptions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        .office-description {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: justify;
          padding: 2rem 3rem 3rem 3rem;

          p {
            width: 100%;
            font-size: 1rem;
            margin: 0;

            &.office-description-emphasis{
              font-weight: bold;
            }
          }
        }

        .office-impact-image{
          img{
            width: 30rem;
            max-width: 30rem;
            aspect-ratio: 1/1;
          }
        }

        @media screen and (max-width: 940px) {
          flex-direction: column !important;
          .office-description {
            padding: 2rem 0rem 3rem 0rem;
          }
          .office-impact-image{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
              height: 'auto';
              object-fit: cover;
            }
          }
        }
      }

      @media screen and (max-width: 940px) {
        .title {
          padding: 0;
        }
      }
    }
  }
}
