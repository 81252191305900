@import '../../../../styles/colors.scss';

.back-to-top {
  position: fixed;
  bottom: 6.5rem;
  right: 1.4rem;
  z-index: 12;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  column-gap: 0.5rem;

  opacity: 0.6;

  transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-in-out;

  .back-to-top-button {
    z-index: 12;

    width: 3rem;
    height: 3rem;

    background-color: $LP-bgColor;

    border-radius: 100%;
    box-shadow: 0px 0px 2px 1px rgba(4, 34, 59, 0.2);
    -moz-box-shadow: 0px 0px 2px 1px rgba(4, 34, 59, 0.2);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    svg {
      display: flex;
      color: white;
      font-size: 2.2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
