@import '../../styles/colors.scss';

.basic-avatar {
  border-radius: 50% !important;
  .avatar {
    background-color: transparent;
    border-radius: 50% !important;
  }
  > img {
    border-radius: 50% !important;
  }
}
