@import '../../../styles/colors.scss';
.budgetPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 20rem;
  max-width: 1250px;
  border-radius: 1rem;
  background-color: #041b27;
  margin: 3rem 1rem 3rem 1rem;
  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/notebook_1280.jpg');
    opacity: 0.4;
    z-index: 0;
    border-radius: 1rem;
  }

  .budgetContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    color: rgb(255, 255, 255);
    z-index: 1;
    h1 {
       margin-bottom: 0.5rem;
       font-size: 2rem;
    }
    p {
      font-size: 20px;
      margin: 10px 0;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
 }

 .container-default-lead{
  background-color: #f9f9fb !important;

  @media screen and (min-width: 1250px) {
    width: 100%;
    background-color: #f9f9fb !important;
  }

  .title-lead{
    font-size: 1.5rem;
    font-weight: bold;
    padding-top: 4rem;
    text-align: center;

  }

  .content-lead {
    display: flex;
    align-content: center;
    justify-content: center;

    .container-lead {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      margin: 8rem 0rem 10rem 0rem;
      width: 1250px;
      min-height: 40rem;
      position: relative;

      .first-container {
        width: 25rem;
        height: 30rem;
        box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
        border-radius: 10px;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      .second-container {
        width: 36rem;
        height: 40rem;
        position: absolute;
        left: 21rem;
        top: -5rem;
        box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
        border-radius: 10px;
        z-index: 2;
        @media screen and (max-width: 1200px) {
          width: 36rem;
        }
        @media screen and (max-width: 550px) {
          width: 25rem;
          left: 26.5rem;
        }
      }

      .third-container {
        width: 25rem;
        height: 30rem;
        box-shadow: 0px 0px 8px 2px rgba(99, 99, 99, 0.2);
        border-radius: 10px;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      .first-container,
      .second-container,
      .third-container {
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 10px;

          &:hover {
            cursor: pointer;
          }
        }

        .container-img {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0rem;
          top: 0rem;

          &:hover {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            cursor: pointer;
          }
        }

        .container-img-1 {
          opacity: 0;
          width: 25rem;
          height: 30rem;
          position: absolute;
          left: 0rem;
          top: 0rem;

          &:hover {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            cursor: pointer;
          }
        }

        .container-img-2 {
          opacity: 0;
          width: 25rem;
          height: 30rem;
          position: absolute;
          left: 53.1rem;
          top: 0rem;

          &:hover {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            cursor: pointer;
          }
        }

        .container-text {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          text-align: center;

          .title {
            margin-bottom: 20px;
            color: #2e3241;
            font-size: 23px;
            font-weight: 600;
          }

          .description {
            color: #9a9da3;
          }
        }
      }
    }
  }

 }
