@import '../../../../styles/colors.scss';

#footer {
  &.container {
    width: 100%;
    .background-image {
      background-color: $LP-bgColor;
    }
    .content {
      min-height: 15rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6rem;
      justify-content: center;
      @media only screen and (max-width: 800px) {
        height: 100%;
      }
      .navigation-footer {
        display: flex;
        justify-content: space-around;
        color: white;
        @media only screen and (max-width: 800px) {
          height: 100%;
          align-items: center;
          flex-direction: column;
          gap: 3rem;
          padding: 3rem;
        }
        .social-footer {
          display: flex;
          flex-direction: column;
          gap: .1rem;

          .social-footer-impact-message{
            padding: 0 1rem;
            margin: 0;
          }

          @media only screen and (max-width: 800px) {
            align-items: center;
            .social-footer-impact-message{
              text-align: center;
            }
          }
        }
        .menu-footer {
          display: flex;
          flex-direction: column;
          @media only screen and (max-width: 800px) {
            align-items: center;
          }
          .menu-footer-title {
            font-weight: bold;
            font-size: 1.5rem;
            padding-bottom: 1rem;
          }
          .menu-footer-links {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 800px) {
              align-items: center;
              gap: 1rem;
            }
            a {
              text-decoration: none;
              color: white;
              cursor: pointer;
              &:hover {
                color: $LP-mgColor;
              }
            }
          }
        }
        .contact-footer {
          display: flex;
          flex-direction: column;
          @media only screen and (max-width: 800px) {
            align-items: center;
          }
          .contact-footer-title {
            font-weight: bold;
            font-size: 1.5rem;
            padding-bottom: 1rem;
          }
          .contact-footer-descriptions {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            p{
              margin: 0
            }

            a {
              text-decoration: none !important;
              color: $LP-iconColor;
            }
            @media only screen and (max-width: 800px) {
              align-items: center;
              text-align: center;
            }
          }
        }
      }
    }
   .container-footer{
    display: flex;
    justify-content: center;
    min-height: 3.5rem;
    .powered-footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0.5rem;
      background-color: white;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;
      color: black;
      gap: 1rem;
      position: relative;
      width: 1250px;
      top: 0px;
      .rights-footer {
        display: flex;
        gap: 1rem;
        @media only screen and (max-width: 800px) {
          align-items: center;
          flex-direction: column;
          gap: 0.2rem;
        }
      }
      .programming-footer {
        a {
          text-decoration: none;
          color: $LP-txtColor-alternative;
          cursor: pointer;
        }
      }
      @media only screen and (max-width: 800px) {
        align-items: center;
        flex-direction: column;
        overflow-wrap: break-word;
      }
    }
   }
  }
}
