$LP-bgColor: #6FCCF9;
$LP-bgColorFd: rgba(4, 34, 59, 0.8);
$LP-mgColor: rgba(160, 128, 95, 1);
$LP-fgColor: rgba(184, 170, 144, 1);
$LP-fgOpColor: rgba(184, 170, 144, 0.9);
$LP-txtColor: rgba(4, 34, 59, 1);
$LP-txtColor-alternative: rgba(255, 250, 242, 1);
$LP-wpColor: #25d366;
$LP-iconColor: rgba(255, 255, 255, 1);

$LP-errorColor: #ed4337;
