@import '../../../styles/colors.scss';

#home {
  &.container {
    height: 100vh;
    width: 100%;
    .background-image {
      background-image: url('../../../assets/images/bg_1.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .content {
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6rem;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.842);
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
          .title {
            font-size: 4rem;
            color: $LP-txtColor-alternative;
            margin-bottom: 0;
            font-weight: bold;
          }


        .content-description{
          display:flex;
          gap:1rem;
          align-items: center;
          justify-content: center;
          .description-sub{
            width: 100px;
            border-width:0;
            margin-top: 2rem;
            height: 2px;
            background-color: white;
          }
          .description {
            font-size: 1.3rem;
            color: $LP-txtColor-alternative;
            margin-bottom: 0;
            font-weight: bold;
            text-align: center;
          }
        }
        .second-description{
          font-size: 1.0rem;
          color: $LP-txtColor-alternative;
          margin-bottom: 0;
          letter-spacing: .05em;
        }
        .typewriter {
          &.second-description {
            overflow: hidden; /* Ensures the content is not revealed until the animation */
            border-right: .1em solid $LP-bgColor; /* The typwriter cursor */
            white-space: nowrap; /* Keeps the content on a single line */
            letter-spacing: .01em; /* Adjust as needed */
            animation:
              typing 3.5s steps(43, end),
              blink-caret 1s step-end infinite;
          }
        }

        /* The typing effect */
        @keyframes typing {
          from { width: 0 }
          to { width: 23rem }
        }

        /* The typewriter cursor effect */
        @keyframes blink-caret {
          from, to { border-color: transparent }
          50% { border-color:  $LP-bgColor; }
        }
      }
      .interaction-button {
        width: 100%;
        display: flex;
        justify-content: center;
        a {
          text-decoration: none !important;
        }
        button {
          display: flex;
          height: 4rem;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          padding: 0 1rem;
          font-size: 1.5rem;
          color: $LP-txtColor-alternative;
          border-radius: 1rem;
          border: none;
          background-color: $LP-mgColor;
          cursor: pointer;
          transition: 0.5s;
          .interaction-whatsapp {
            width: 1.5rem;
            font-size: 1.5rem;
          }
          &:hover {
            transition: 0.5s;
            background-color: $LP-bgColor;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        align-items: center;
        .text {
          max-width: 100%;
          height: 65%;
          padding: 0 3rem;
        }
      }
    }
  }
}
