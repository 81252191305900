@import 'styles/colors.scss';

.basic-button {
  min-width: 1rem;
  height: 2.5rem;

  text-align: center;
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  transition: 0.5s;

  svg {
    display: flex;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.01);
    background-color: $LP-bgColor !important;
    transition: 0.5s;
  }

  &:active {
    transform: scale(0.99);
  }
}
