@import '../../../styles/colors.scss';

#statistics {
  &.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 2rem 2rem 2rem;
    background-color: rgba(255, 255, 255, 0.9);

    .content {
      width: 100%;
      max-width: 1250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: .5rem;

      .title {
        font-size: 1.5rem;
        font-weight: bold;
        padding-bottom: 1rem;
        width: 100%;
        padding: 0rem 3rem;

        @media screen and (max-width: 940px) {
          margin: 1rem 0 0 0;
          padding: 0;
        }
      }

      .count-area{
        width: 100%;
        padding: 0rem 3rem;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        gap: 2rem;

        .count-item{
            width: 25%;
            height: 8rem;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;


            gap: .2rem;

            h1{
                font-size: 40px;
            }

            h6{
                color: gray;
            }


            h1, h2, h3, h4, h5, h6{
                margin: 0;
            }

            span{
                color: $LP-bgColor;
            }

            .count-item-description{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                gap: .5rem;

                svg{
                    font-size: 2rem;
                    color: $LP-bgColor;
                }
            }
        }


        @media screen and (max-width: 940px) {
            flex-direction: column;
            padding: 0;
            .count-item{
                width: 100%;

            }
        }
      }
    }
  }
}
